import { useState, useEffect, useRef } from 'react';
import styles from './module/navbar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import MobileNavBar from './MobileNavBar';
import { Link } from 'react-router-dom';
import { setProfile } from './store/userProfile/userProfileActions';
import languages from './resources/languages.json';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetProfile } from './store/userProfile/userProfileActions';

const NavBar = () => {
    const [toggleHamBurger, setToggleHamBurger] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dialogRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const isActive = useSelector(state => state.stickactive.stickactiveValue);
    const profile = useSelector(state => state.profile);

    const handleDialogToggle = () => {
        setIsDialogOpen(prev => !prev);
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };



    const handleClickOutsideForDialog = (event) => {
        if (
            dialogRef.current && !dialogRef.current.contains(event.target)) {

            setIsDialogOpen(false);
        }
    };


    const OpenviewProfile = () => {
        navigate('/userprofile')
    }

    const handleLogout = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('token');
        const newUrl = `${location.pathname}?${queryParams.toString()}`;
        localStorage.removeItem('profile');

        dispatch(resetProfile());

        navigate(newUrl);
        setIsDropdownOpen(false);
        setIsDialogOpen(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);



    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideForDialog);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideForDialog);
        };
    }, [dialogRef]);



    useEffect(() => {
        // Retrieve the profile data from localStorage
        const profileData = JSON.parse(localStorage.getItem('profile'));
      
        // Check if profileData exists and its fields are not empty
        if (
          profileData &&
          profileData.username?.trim() !== "" &&
          profileData.email?.trim() !== "" &&
          profileData.mobile?.trim() !== "" &&
          profileData.area?.trim() !== ""
        ) {
          // Log the profile data and dispatch the action to set the profile in Redux store
          console.log(profileData);
          dispatch(setProfile({
            userid: profileData.userid,
            username: profileData.username,
            email: profileData.email,
            usagecount: profileData.usagecount,
            image: profileData.image,
            token: profileData.token
          }));
        }
      }, [dispatch]);


    return (
        <div className={isActive ? styles.sticky : styles.navBar}>
            <div className="flex items-center">
                <img
                    src='/ZMQRCODEORIGINALOGO.jpg'
                    alt='ZM QR Code Logo'
                    className={styles.logo}
                />
                <div className="flex flex-col items-center text-white mt-[16px] ml-4">
                    <label className="text-2xl font-semibold text-white font-raleway">
                        zmqrcode.Com
                    </label>
                    <p className="text-base text-white font-raleway">
                        Your Security is Our Priority
                    </p>
                </div>
            </div>
            <div className='flex items-center'>
                <div className={styles.navigation}>
                    <ul className='list-none hidden sm:flex pr-[15px] font-raleway'>
                        <li className='flex flex-center'>
                            <Link to="/" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">Home</Link>
                            <Link to="/services" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">Generate Qr Code</Link>
                            <Link to="/aboutus" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">About Us</Link>
                            <Link to="/contact-us" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">Contact Us</Link>
                            <Link to="/faq" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">FAQ</Link>
                            <Link to="/pricing" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">Pricing</Link>
                            <Link to="/refund" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">Refund Policy</Link>

                            {/* Languages Dropdown */}
                            <div className="relative text-white flex-col text-[15px] p-4 hover:bg-white hover:text-customBlue rounded  m-4" ref={dropdownRef}>
                                <button
                                    onClick={handleDropdownToggle}
                                    className="w-full text-left flex items-center justify-between"
                                    aria-expanded={isDropdownOpen}
                                    aria-controls="language-dropdown"
                                >
                                    Languages
                                    <span className={`transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}>
                                        ▼
                                    </span>
                                </button>
                                {isDropdownOpen && (
                                    <ul id="language-dropdown" className="absolute top-full left-0 bg-white text-black w-full mt-2 p-2 rounded shadow-lg  m-4">
                                        {languages.map((item) => (
                                            <li key={item.code} className='p-2 hover:bg-gray-200'>
                                                <Link to="/comingsoon">{item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            {/* User Profile Dialog */}

                            {profile.username && (
                                <div className="relative">
                                    <button
                                        onClick={handleDialogToggle}
                                        className="w-full text-left flex items-center justify-between"
                                        aria-expanded={isDialogOpen}
                                        aria-controls="profile-dialog"
                                    >
                                        <FontAwesomeIcon
                                            icon={faUser}
                                            size="1x"
                                            className="text-white p-5 mt-4 hover:text-customBlue hover:bg-white rounded"
                                        />
                                    </button>
                                    {isDialogOpen && (
                                        <div
                                            id="profile-dialog"
                                            className={`${styles.profileDialog} ${styles.profileDialogDesktop}`}
                                            ref={dialogRef}
                                        >

                                            <div className="w-full bg-white rounded-lg" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                                                <h3 className="text-lg font-semibold text-center">{`Hi, ${profile.username}`}</h3>
                                                <div className="flex p-4 justify-center space-x-4">
                                                    <button
                                                        className=" bg-white text-customBlue  hover:bg-customBlue hover:text-white  font-bold py-2 px-4 rounded"
                                                        onClick={OpenviewProfile}
                                                    >
                                                        Visit Your Profile
                                                    </button>
                                                    <button
                                                        className="bg-white text-[#FF2400] hover:bg-[#FF2400] hover:text-white font-bold py-2 px-4 rounded font-raleway"
                                                        onClick={handleLogout}
                                                    >
                                                        Logout
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
                <div className={styles.hamburger}>
                    <FontAwesomeIcon
                        icon={toggleHamBurger ? faTimes : faBars}
                        className="text-white text-2xl cursor-pointer hover:text-gray-300"
                        onClick={() => setToggleHamBurger(prev => !prev)}
                    />
                    {toggleHamBurger && <MobileNavBar setToggleHamBurger={() => setToggleHamBurger(false)} />}
                </div>
            </div>
        </div>
    );
};

export default NavBar;
