/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

const OutPutWindow = () => {
  const location = useLocation();
  const [data, setData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null);
  const [paths, setFilePath] = useState([]); // State for file paths

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get the 'id' parameter

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.zmqrcode.com/purchasedQr/${id}` // Replace with your API URL
        );
        setData(response.data); // Set the fetched data to state

        // Safely parse filePaths if it's a string
        const filePaths = response.data.filePaths;
        let parsedFilePaths = [];

        if (typeof filePaths === "string") {
          try {
            parsedFilePaths = JSON.parse(filePaths); // Parse string to array
          } catch (err) {
            console.error("Error parsing filePaths:", err);
          }
        } else {
          parsedFilePaths = filePaths; // If it's already an array, use it as is
        }

        setFilePath(parsedFilePaths); // Set the file paths to state
      } catch (err) {
        setError(err); // Set the error if the request fails
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchData(); // Call the fetch function
  }, [id]); // Adding id as a dependency to refetch if it changes

  // Early return for loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Destructure necessary data
  const { serviceName } = data || {}; // Ensure data is not null before destructuring

  // Functionality for rendering videos
  const renderVideoList = () => {
    if (paths.length === 0) {
      return <div>No videos available</div>;
    }

    return paths.map((link, index) => (
      <div key={index} className="mb-[15px] flex flex-row">
        <video controls className="p-[16px] rounded-mg">
          <source
            src={`https://backend.zmqrcode.com/getUploadForUserByFileId/${link}`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    ));
  };

  const renderImageGallery = () => {
    if (paths.length === 0) {
      return <div>No Images Available</div>; // Removed quotes around the message
    }

    return (
      <div className="flex flex-col justify-center items-center">
        <div className="flex w-full flex-wrap flex-wrap">
          {paths.map((link, index) => (
            <div key={index} className="mb-[15px] flex flex-col">
              <img
                src={`https://backend.zmqrcode.com/getUploadForUserByFileId/${link}`}
                alt="Image not uploaded" // Updated alt text for clarity
                className="max-w-full h-auto" // Optional: Add class for responsive images
              />
              <label className="font-raleway font-semibold">{index}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderAudioFiles = () => {
    if (paths.length === 0) {
      return <div>No Audio Files Available</div>; // Removed quotes around the message
    }

    return (
      <div className="flex flex-col justify-center items-center">
        <div className="flex w-full flex-wrap">
          {paths.map((link, index) => (
            <div key={index} className="mb-[15px] flex flex-col">
              <div className="flex flex-col">
                <img
                  src="/images/audio.jpg"
                  alt="image not loaded"
                  className="w-[175px] h-[75px]"
                />
                <audio
                  controls
                  className="p-[16px] rounded-mg w-[175px] m-[16px]"
                >
                  <source
                    src={`https://backend.zmqrcode.com/getUploadForUserByFileId/${link}`}
                    type="audio/mpeg" // Corrected to audio/mpeg for audio files
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <label className="font-semibold">{`Audio(${index})`}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };

const renderDocumentFiles = () => {
    if (paths.length === 0) {
        return <div>No Documents Files Available</div>;
    }

    return (
        <div className="flex flex-col justify-center items-center">
            <div className="flex w-full flex-wrap">
                {paths.map((link, index) => (
                    <div key={index} className="mb-[15px] flex flex-col">
                        <div className="flex flex-col">
                            <iframe
                                src={`https://backend.zmqrcode.com/getUploadForUserByFileId/${link}`}
                                className="w-full h-[500px] border-none p-[16px] m-[16px]"
                                title={`Document Viewer ${index}`} // Ensure each title is unique
                            ></iframe>
                        </div>
                        <label className="font-semibold">{`Document(${index})`}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};


  switch (serviceName) {
    case "Video":
      return (
        <>
          <h2>Video List</h2>
          {renderVideoList()}
        </>
      );
    case "Image Gallery":
      return (
        <>
          <h2 className="font-bold text-[26px]">Image Gallery</h2>
          {renderImageGallery()}
        </>
      );
    case "Audio":
      return (
        <>
          <h2 className="font-bold text-[26px]">Audio</h2>
          {renderAudioFiles()}
        </>
      );
    case "Document":
      return (
        <>
          <h2 className="font-bold text-[26px]">Document Files</h2>
          {renderDocumentFiles()}
        </>
      );
      case "Others":
        return (
          <>
            <h2 className="font-bold text-[26px]">Others</h2>
            {renderImageGallery()}
          </>
        );
        case "Image":
          return (
            <>
              <h2 className="font-bold text-[26px]">Others</h2>
              {renderImageGallery()}
            </>
          )
    default:
      return <div>Unsupported service type: {serviceName}</div>;
  }
};

export default OutPutWindow;
