import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import CustomAlert from "../CustomAlertForSubmision";
import { useNavigate } from "react-router-dom";

const ClientRegistraionForm = () => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  //Image form States

  //document form states

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [buttonText, setButtonText] = useState("Submit");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const togglePasswordVisibility = () =>
    setPasswordVisible((prevState) => !prevState);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    password: "",
  });

  const [buttonColor, setButtonColor] = useState("#517b7b");



  const [error, setError] = useState({
    username: "",
    email: "",
    mobile: "",
  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData({ ...formData, [name]: value });
    setButtonText("Submit");
    setButtonColor("#517b7b");
  
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    let errorMessages = { ...error }; // Copy existing errors
  
    
  
    if (name === "email") {
      if (!emailRegex.test(value)) {
        errorMessages.email = "Your email format is invalid.";
      } else {
        errorMessages.email = ""; // Clear error if valid
      }
    }
  
    if (name === "mobile") {
      if (value.length !== 10) {
        errorMessages.mobile = "Your mobile number must be exactly 10 digits.";
      } else {
        errorMessages.mobile = ""; // Clear error if valid
      }
    }
  
    // Update the error state
    setError(errorMessages);
  };

  const handleSubmitForUserRegister = async (event) => {
    setButtonText('Submitted');
    event.preventDefault(); // Prevent default form submission

    
    setError({
      username: "",
      email: "",
      mobile: "",
    });

    let hasError = false;

    if (formData.username === "") {
      setError((prev) => ({ ...prev, username: "Your Username is empty" }));
      hasError = true;
    }
    if (formData.email === "") {
      setError((prev) => ({ ...prev, email: "Your email is empty" }));
      hasError = true;
    }
    if (formData.mobile === "") {
      setError((prev) => ({ ...prev, mobile: "Your mobile is empty" }));
      hasError = true;
    }

    if (!hasError) {
      try {
        const response = await axios.post(
          "https://backend.zmqrcode.com/registerUser",
          formData
        );
        const data = response.data.data;
        const registeredUserData = {
          userid: data.id,
          username: data.username,
          email: data.email,
          usagecount: data.usagecount || 0,
          token: data.token || "",
          image: data.image || "",
        }

        localStorage.setItem('registeredUserData', JSON.stringify(registeredUserData));


        setButtonText("Submitted");
        if (response.status === 201) {
        

          setFormData({
            username: "",
            email: "",
            mobile: "",
            password: "",
          });

          setShowAlert(true);
          setAlertMessage("User Registered Successfully");
          setButtonText("Submitted");
          setButtonColor("#37CE98");

          navigate("/QrCodeGenerationform");
        }
      } catch (error)  {
        let message = "An unexpected error occurred."; // Default error message
      
      // Check if there's a response with a status and message from the server
      console.log(error);
      if (error.response) {
     
        if (error.response.status === 400) {
          message = error.response.data.msg || "Invalid request.";
        } else {
          message = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      }
    
      setShowAlert(true);
      setAlertMessage(message);
  };
}
}

  return (
    <div className="max-w-md mx-auto  p-6 rounded-md shadow-md bg-customBlue font-raleway">
      <div className="flex justify-between items-center mb-4 ">
        <h1 className="text-2xl font-semibold text-left">
          Client Registration
        </h1>
        <img
          src="images/ZMlogoforclienregistraionForm.png"
          alt="Logo"
          className="h-10 w-10 object-contain"
        />
      </div>

      <form onSubmit={handleSubmitForUserRegister}>
        <div>
          <div className="mb-4">
            <label className="block text-gray-700">
              Employee ID / Name (Optional)
            </label>
            <input
              type="text"
              name="referralId"
              value={formData.referralId}
              onChange={handleInputChange}
              placeholder="Enter Employee ID / name"
              className="w-full mt-1 p-2 border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Full Name</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              placeholder="Enter your full name"
              className="w-full mt-1 p-2 border rounded-md"
            />
            {error.username !== "" && (
              <label
                id="usernameError"
                className="text-red-500 text-[12px] font-raleway"
              >
                {error.username}
              </label>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email address"
              className="w-full mt-1 p-2 border rounded-md"
            />
            {error.email !== "" && (
              <label
                id="usernameError"
                className="text-red-500 text-[12px] font-raleway"
              >
                {error.email}
              </label>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Mobile Number</label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="Enter your mobile number"
              className="w-full mt-1 p-2 border rounded-md"
            />
            {error.mobile !== "" && (
              <label
                id="usernameError"
                className="text-red-500 text-[12px] font-raleway"
              >
                {error.mobile}
              </label>
            )}
          </div>

          <div className="flex items-center border rounded-lg w-full p-3 m-[5px] bg-white">
            <FontAwesomeIcon
              icon={passwordVisible ? faEye : faEyeSlash}
              onClick={togglePasswordVisibility}
              size="1x"
              color="#1D91AA"
            />
            <input
              className="border-none ml-2 p-1 w-full focus:outline-none"
              name="password"
              value={formData.password}
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter the Password"
              onChange={handleInputChange}
              aria-describedby="passwordError"
            />
          </div>
          <button
            type="submit"
            disabled={!!(error.email || error.mobile || error.password)}
            style={{
              backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
              color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
            }}
            className={`${
              buttonText === "submit" ? "w-20" : "w-40"
            } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
              isHovered ? "scale-105" : "scale-100" // Scale effect on hover
            }`}
            onMouseEnter={() => setIsHovered(true)} // Set hover state
            onMouseLeave={() => setIsHovered(false)} // Reset hover state
          >
            {buttonText}
          </button>

     {showAlert && (
            <CustomAlert
              message={alertMessage}
              onClose={() => {
                setShowAlert(false);
              }}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ClientRegistraionForm;
