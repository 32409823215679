import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const GoogleMeetForm = () => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">QR Code Generator for Meeting</h1>
      <Formik
        initialValues={{
          companyName: '',
          purposeOfMeet: '',
          timings: '',
          url: '',
          password: ''
        }}
        onSubmit={(values) => {
          // Handle form submission
          console.log(values);
        }}

      >
        {({ isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Company Name input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
                Company Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="companyName"
                placeholder="NA"
              />
            </div>

            {/* Purpose of Meet input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="purposeOfMeet">
                Purpose of Meet
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="purposeOfMeet"
                placeholder="NA"
              />

            </div>

            {/* Timings input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="timings">
                Timings
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="timings"
                placeholder="NA"
              />

            </div>

            {/* URL input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                URL
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="url"
                placeholder="NA"
              />

            </div>

            {/* Password input with validation */}
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute 
                                    md:right-[15px]
                                    top-1/2
                                    right-[-70px]
                                    text-gray-600 hover:text-gray-900">
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className="  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: '#1D91AA' }}
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GoogleMeetForm;
