import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import convertBitsToMb from "../convertbits/convertBitsToMb";
import convertBitsToGB from "../convertbits/convertbitstoGb";
import convertBitsToTB from "../convertbits/convertBitsToTB";
import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";

const AudioUploadForm = () => {
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [files, setFiles] = useState([]);
  const [switchButton, setSwitchButton] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const [filePaths, setFilePath] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [buttonText, setButtonText] = useState("Submit");
  const [alertMessage,setAlertMessage] = useState("");
  const [showAlert,setShowAlert] = useState("");
  const isMobile = useMediaQuery("(max-width: 960px)");


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("name");
    if (queryParam) {
      setServiceName(queryParam);
    }
  }, [location]);

  useEffect(() => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size in bytes
    if ((totalSize>0)&&(totalSize <= 52428800)) {
      const plan = convertBitsToMb(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if ((totalSize > 52428800)&&(totalSize <= 96262144000)) {
      const plan = convertBitsToGB(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if ((totalSize > 96262144000)&&(totalSize > 96262144000)) {
      const plan = convertBitsToTB(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    }
  }, [files, dispatch]);







  const chooseFileHandleChange = async (event, setFieldValue) => {
    setButtonText("Submit");
    if (profile.username !== "") {
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setFieldValue("files", selectedFiles);

      const formData = new FormData();

      selectedFiles.forEach((file) => {
        formData.append("file", file); // Make sure 'file' matches the expected field name in the backend
      });

      try {
        const uploadData = await postUploadFile(
          "https://backend.zmqrcode.com/uploadFileForUser",
          formData
        );

        if (Array.isArray(uploadData.uploadedFiles)) {
          const uploadedFileIds = uploadData.uploadedFiles.map(file => file.fileId);

          setFilePath(uploadedFileIds); // Store only file IDs in state
        } else if (typeof uploadData.fileId === "string") {
          setFilePath((prevFilePaths) => [...prevFilePaths, uploadData.fileId]);
        }
        dispatch(setReset());
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsLogin(true);
    }
  };

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error during upload:", error);
      throw error;
    }
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmitService = async (values, { resetForm }) => {
    if (profile.username !== "") {
      const formData = {
        serviceName: serviceName,
        fileTitle: values.AudioTitle,
        fileDescription: values.description,
        filePaths: filePaths,
        QrCodePassword: values.password || "",
        userId: profile.userid,
      };

      try {
        if (filePaths.length >= 1) {
          const response = await axios.post(
            "https://backend.zmqrcode.com/purchaseQrCodeUser",
            formData
          );
          const purchasedServiceId = response.data.url;
          if (purchasedServiceId) {
            dispatch(setInstanceId(purchasedServiceId));
          }
          setButtonText("Submitted");
        }

        resetForm();
        setFiles([]); // Clear the files array
        setFilePath([]); // Clear the file paths
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Reset input field
        }
      } catch (error) {
        console.error("Error creating service instance:", error);
      }
    } else {
      setIsLogin(true);
    }
  };

  const BestPlans = useSelector((state)=>(state.Bestplans))
  
  useEffect(()=>{
    if((BestPlans.price!==null)&&(BestPlans.price!==0)){
    setAlertMessage(`Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`);
    setShowAlert(true);
    }
  },[BestPlans])


  
 





  
  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">
        QR Code Generator for Audio
      </h1>
      <Formik
        initialValues={{
          AudioTitle: '',
          description: "",
          password: "",
          files: [],
        }}
        onSubmit={handleSubmitService}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Recording Audio input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="recordingAudio"
              >
                Audio Title
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="AudioTitle"
                placeholder="NA"
              />
            </div>

            {/* Audio Name input */}
            {/* <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="audioName">
                                Recording Audio
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="audioName"
                                placeholder="NA"
                            />
                        </div> */}

            {/* Movie Name input
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="movieName">
                                Movie Name
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="movieName"
                                placeholder="NA"
                            />
                        </div>


                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="musicDirectorName">
                                Music Director Name
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="musicDirectorName"
                                placeholder="NA"
                            />
                        </div> */}

            {/* Description input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <Field
                as="textarea"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="description"
                placeholder="NA"
              />
            </div>

            {/* File input with multiple selection and handling */}
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Audio Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept="audio/*"
                multiple
                onChange={(event) =>
                  chooseFileHandleChange(event, setFieldValue)
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>

            {/* Display uploaded files with delete option */}
            <div className="mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-customBlue" />
                    </button>
                  </div>
                ))}
              </div>

            {/* Password input */}
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className={`absolute 
                    md:right-[15px]
                    top-1/2
                    right-[-70px]
                    text-gray-600 hover:text-gray-900 ${isMobile && "px-6"}`}
    
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    size="1x"
                    color="#1D91AA"
                  />
                </button>
              </div>
            </div>

            {/* Submit button */}
              {/* Submit button */}
              <div className="flex items-center justify-between">
              <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: "#1D91AA" }}
                disabled={isSubmitting}
              >
                {buttonText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showAlert && <CustomAlert message={alertMessage} onClose={() => { setShowAlert(false); }} />}
      {isLogin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-96">
            {switchButton ? (
              <LoginUp setIsLogin={setIsLogin} setSwitchButton={setSwitchButton} />
            ) : (
              <SignUp setIsLogin={setIsLogin} setSwitchButton={setSwitchButton} />
            )}
          </div>
        </div>
      )}
    </div>
    
  );
};

export default AudioUploadForm;
