import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../CustomAlertForSubmision';

const BringYourFriend = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } 
        if (name === 'name') {
            setName(value);
        }
    };

    const handleSubmit = async () => {
        const friendInfo = {
            name: name,
            email: email
        };

        try {
            const response = await axios.post('https://backend.zmqrcode.com/bringYourFriend', friendInfo);
            if (response.status === 201) {
                setAlertMessage("Message sent successfully");
                setShowAlert(true); // Show alert
                setTimeout(() => {
                    setShowAlert(false); // Hide alert after 3 seconds
                    navigate('/userprofile');
                }, 3000);
            }
        } catch (error) {
            console.log(error);
            setAlertMessage("Failed to send message. Please try again.");
            setShowAlert(true); // Show alert on error
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-customBlue bg-opacity-50 z-50">
            <div className="bg-[#1D91AA] flex flex-col text-white p-6 rounded-lg shadow-lg w-90">
                <label className="font-raleway text-lg m-4 font-bold text-white">
                    Please Enter Your Friend's Email ID
                </label>
                <input
                    type="email"
                    name="email"
                    className="border-2 border-[#f0ebeb] rounded-lg p-4 mb-4"
                    placeholder="Enter Your Friend's Email"
                    onChange={handleChange}
                />
                <label className="font-raleway text-lg m-4 font-bold text-white">
                    Please Enter Your Friend's Name
                </label>
                <input
                    type="text"
                    name="name"
                    className="border-2 border-[#f0ebeb] rounded-lg p-4 mb-4"
                    placeholder="Enter Your Friend's Name"
                    onChange={handleChange}
                />
                <button
                    className="bg-white text-customBlue hover:bg-customBlue hover:text-white font-bold py-2 px-4 rounded flex items-center justify-center w-full mt-4"
                    onClick={handleSubmit}
                >
                    Send
                </button>
            </div>
            {showAlert && (
                <CustomAlert
                    message={alertMessage}
                    onClose={() => setShowAlert(false)}
                />
            )}
        </div>
    );
};

export default BringYourFriend;
